<template>
  <div id="form-header">
    <order-information-view
      class="mb-1"
      :quote="{
        ...order,
        supplier: order.customer,
        dispatch_contact: order.dispatch_contact && {...order.dispatch_contact,firstname: order.dispatch_contact.first_name,lastname: order.dispatch_contact.last_name,phone: order.dispatch_contact.phone_one},
        billing_address: order.return_warehouse
      }"
    />
    <approve-stock-items />
    <other-charges
      class=" mb-1"
    />
    <attachment
      class="mb-1"
      :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
    />
    <notes-and-totals />
    <form-bottom
      :form-ref="$refs"
      :buttons-list="approveProgressButtons"
    />
  </div>
</template>

<script>
import OrderInformationView
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/OrderInformation.vue'
import Attachment
from '../../components/approve/Attachment.vue'
import NotesAndTotals
from '../../components/approve/NotesAndTotals.vue'
import OtherCharges from '../../components/OtherCharges.vue'
import ApproveStockItems
from '../../components/approve/ApproveStockItems.vue'
import formBtnConfig from '../../components/form-submit-buttons/libs'
import config from '../../config'
import FormBottom from '../../components/form-submit-buttons/FormBottom.vue'

export default {
  name: 'DirectApprove',
  components: {
    OrderInformationView,
    Attachment,
    OtherCharges,
    ApproveStockItems,
    NotesAndTotals,
    FormBottom,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const {
      approveProgressButtons,
    } = formBtnConfig()

    return {
      MODULE_NAME,
      approveProgressButtons,
    }
  },
}
</script>
